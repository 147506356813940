.image-creator {
    --icon-size: 28px;
    --color-icon: var(--bs-danger);
}

.dice {
    font-size: var(--icon-size);
    /*position: absolute; Animation becomes active again, but overlaps with button*/
    position: relative;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    right: 0;
}
.container-dice {
    color: var(--color-icon);
    display: inline-block;
    height: calc(var(--icon-size) + 10px);
    margin: 0px 10px 0px 10px;
    vertical-align: bottom;
    width: calc(var(--icon-size));
}
.container-explanation {
    background-color: var(--color-background-soft);
    border-radius: 0.5em;
    padding: 1em;
}
.container-options {
    display: block;
    max-height: 90px;
    overflow-x: scroll;
}
.container-options-group {
    border: 2px solid var(--color-secondary);
    border-radius: 0.8em;
    margin-bottom: 10px;
}
.container-image-creator {
    box-sizing: border-box;
    overflow-x: scroll;
    position: relative;
    width: 100%;
}
.ghostly-button {
    height: 100%;
    opacity: 0.8;
    text-align: center;
    width: 100%;
}
.input-search-options {
    background-color: var(--color-background-soft);
    border: none;
    border-radius: 0.5em;
    color: var(--color-text);
    width: 98%;
}

*, *::before, *::after {
    box-sizing: inherit;
  }