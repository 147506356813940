#container-about {
    --border-radius-about: 3em;
}

i {
    color: var(--color-icon);
    margin: var(--margin-icon);
}

.me {
    border: 2px dashed var(--color-secondary);
    border-radius: var(--border-radius-about);
    height: auto;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    min-height: 200px;
    min-width: 200px;
    width: auto;
}
.me_container {
    position: sticky;
}
.me_container .overlay:hover {
    opacity: 1;
}
.me_text {
    color: var(--color-text);
    font-size: 1.25rem;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.overlay {
    background-color: var(--color-primary);
    border-radius: var(--border-radius-about);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    text-align: center;
    transition: .5s ease;
    width: 100%;
}
.social_container {
    font-size: 1.8rem;
    text-align: center;
}