.content-container {
    --icon-size: xx-large;
    --color-icon: var(--color-primary);
}
/*Reduce mergin with Back button*/
.content-container h2, h3, h4, h5, h6 {
    margin-top: 0.5em;
  }
.container-canvas {
    overflow-x: scroll;
    overflow-y: scroll;
}
.container-dice-gallery {
    color: var(--color-primary);
}
.div-back {
    color: var(--color-primary);
    font-weight: 600;
    font-size: smaller;
    position: relative;
    display: block;
    left: -20px;
}
.div-back:hover {
    color: var(--color-primary-soft);
    cursor: pointer;
}
.div-copy {
    cursor: pointer;
    background-color: var(--color-primary);
    border-radius: 0.3em;
    text-align: center;
    font-weight: 600;
}
.div-copy>i{
    color: var(--color-background);
    float: right;
}
.div-copy #requestID {
    background-color: var(--color-primary);
    border-radius: 0.3em;
    font-size: 2rem;
}
.dropdown-primary button{
    background-color: var(--color-primary);
}
.dropdown-toggle {
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
}
.dropdown-primary button:hover{
    background-color: var(--color-primary-soft);
}
.dropdown-primary button:focus{
    background-color: var(--color-primary);
}
.dropdown-primary.dropdown-menu.show{
    width: inherit;
    text-align: inherit;
    background-color: var(--color-primary);
}
.dropdown-primary .dropdown-item{
    background-color: var(--color-primary);
    color: var(--color-text);
}
.dropdown-primary .dropdown-item:hover{
    background-color: var(--color-primary-soft);
}
.form-range::-moz-range-thumb {
    background-color: var(--color-primary);
}

.dropdown-primary .dropdown-item.disabled{
    background-color: var(--color-secondary);
    color: var(--bs-dropdown-link-disabled-color);
}

.content-container .multi-range-slider{
    border: none;
    box-shadow: none;
}
.content-container .multi-range-slider .bar-left, .content-container .multi-range-slider .bar-right {
    box-shadow: none;
}

.content-container .multi-range-slider .bar-inner {
    box-shadow: none;
    border: none;
    background-color: var(--color-primary-soft);
}

.content-container .multi-range-slider .thumb::before {
    border: none;
    box-shadow: none;
    background-color: var(--color-primary);   
}
.content-container .multi-range-slider .thumb .caption *{
    color: var(--color-text);
    background-color: var(--color-primary-soft);
}
.content-container .multi-range-slider .caption {
    left: 0;
    width: 0;
}
.content-container .multi-range-slider .thumb:active .caption {
    display: none;
}
.content-container .form-range:focus::-moz-range-thumb {
    box-shadow: none;
    background-color: var(--color-primary);
}

.but-req {
    background-color: var(--color-donations);
}
.but-req:first-child:hover,.but-req:first-child:focus,.but-req:first-child:focus-visible,.but-req:first-child:active {
    background-color: var(--color-donations-soft);
}