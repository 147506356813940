:root {
    --white: #ffffffff;
    --dark-purple: #161032ff;
    --dark-purple-soft: #494365ff;
    --cocoa-brown: #e06d06ff;
    --cocoa-brown-soft: #ee7e17ff;
    --tigers-eye: #b26700ff;
    --honolulu-blue: #067bc2ff;

    --color-text: var(--white);
    --color-background: var(--dark-purple);
    --color-background-soft: var(--dark-purple-soft);
    --color-primary: var(--cocoa-brown);
    --color-primary-soft: var(--cocoa-brown-soft);
    --color-secondary: var(--tigers-eye);
    --color-terciary: #5E4AE3;
    --color-alert: red;
    --color-donations: #48b3e7;
    --color-donations-soft: #59c4f8;

    --border-color-btn: transparent;

    --color-btn-hover: var(--white);
    --bg-color-btn-hover: var(--color-secondary);
    --border-color-btn-hover: transparent;
    --color-icon: var(--color-secondary);
    --margin-icon: auto 1em auto 1em;

    --carousel-max-height: 500px;
    --carousel-min-height: 100px;
    --carousel-button-height: 3rem;
}

:root * {
    /*BOOTSTRAP CHANGES*/
    --bs-btn-active-bg: var(--color-secondary);
    --bs-btn-active-border-color: transparent;
    --bs-btn-bg: var(--color-primary);
    --bs-btn-border-color: transparent;
    --bs-btn-disabled-bg: var(--color-primary-soft);
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-hover-bg: var(--color-primary-soft);
    --bs-btn-hover-border-color: transparent;

    --bs-card-bg: var(--color-text);

    /*--bs-toast-header-bg: var(--color-secondary);
    --bs-toast-header-color: var(--color-text);
    --bs-toast-bg: var(--color-background-soft);
    --bs-toast-color: var(--color-text);*/
}

#root {
    background-color: var(--color-background);
    box-sizing: border-box;
    min-height: 100vh;
    padding-bottom: 50px;
    text-rendering: optimizeLegibility;
    
    /*Word breaking*/
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    /*Support for old browsers*/
    word-wrap: break-word;
}

.mw-100 {
    max-width: 100%;
}

.navbar {
    --nav-link-color: var(--color-text);
}

a {
    text-decoration: underline;
    color: var(--color-secondary);
}
a.disabled {
    pointer-events: none;
}
.a-donation {
    text-align: center;
}
button.but-flag,label.lbl-country {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 2px solid transparent;
}
.navbar-mau {
    background-color: var(--color-primary);
    border-color: var(--border-color-btn);
}

.content-container {
    background-color: var(--color-background-soft);
    border: 2px solid var(--color-secondary);
    border-radius: 1em;
    box-sizing: border-box;
    color: var(--color-text);
    padding: 25px;
    margin: 50px auto auto auto;
    width: 85%;
}
@media (max-width: 800px) {
    .content-container {
        width: 95%;
        padding: 15px;
    }
}
.content-container h1 {
    line-height: 52px;
    font-size: 2.6rem;
    margin-bottom: 0.27em;
    font-weight: 500;
}
.content-container h1.title {
    color: var(--color-text);
    font-weight: 700;
}
.content-container h4 {
    color: var(--color-text);
}
.content-container h1,h2,h3,h4,h5,h6 {
    color: var(--color-text);
    font-family: var(--body-head-font-family);
    font-weight: 700;
    letter-spacing: -0.011em;
}
.content-container h2,h3,h4,h5,h6{
    margin-top: 2em;
}
.content-container img {
    max-height: 95%;
    max-width: 100%;
}
.content-container ol li::marker {
    color: var(--color-primary-soft)
}
.content-container ul li::marker {
    color: var(--color-primary-soft)
}
.content-container .subtitle {
    color: var(--color-text);
    font-weight: 400;
    margin-top: 0;
}

.container-donations button{
    background-color: var(--color-donations);
}
.container-donations button:hover{
    background-color: var(--color-donations-soft);
}
.container-donations button:focus{
    background-color: var(--color-donations);
}
.container-donations .dropdown-menu.show{
    background-color: var(--color-donations);
}
.container-donations .dropdown-item:hover{
    background-color: var(--color-donations-soft);
}
.dropdown-menu[data-bs-popper].dropdown-r0 {
    left: auto;
    right: 0;
}

hr {
    color: var(--color-text)
}
.img-donation {
    border: 0px;
    height: 44px;
}
img.logo {
    max-height: 50px;
    max-width: 150px;
    height: 100%;
    width: 100%;
}
.mandatory {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.mandatory.danger {
    border: 2px solid var(--color-alert);
}
.lbl-button {
    margin-right: 10px;
}

.nav-link-mau {
    border-bottom: 3px solid transparent;
    color: var(--color-background);
    transition: color .5s ease-out,border-color .5s ease-out;
}
.nav-link-mau:hover {
    border-bottom: 3px solid var(--nav-link-color);
    color: var(--nav-link-color);
}

span.small {
    font-size: 1rem;
}
span.danger {
    color: var(--color-alert);
}
figure {
    text-align: center;
}
figure>a {
    display: inline-block;
    height: 100%;
}
figure>a>img {
    background-color: rgba(255,255,255,0.8);
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: calc(var(--carousel-max-height) * 0.95);
    max-width: calc(var(--carousel-max-height) * 0.95);
    object-fit: cover;
    position: relative;
}
figure>a>img:hover {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: imgScale;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-name: imgScale;
}
.bg-language {
    margin-left: 1rem;
}
.tb-language {
    padding: 0;
    font-size: 1.5em;
}

/* CUSTOM TAGS */

mau-carousel {
    background-color: rgba(0,0,0,0.175);
    border: 1px var(--color-primary-soft) dashed;
    color: white;
    display: block;
    margin-top: 2em;
    min-height: var(--carousel-min-height);
    position: relative;
}
mau-carousel>figure {
    display: none;
    height: calc(var(--carousel-max-height) * 0.95);
    opacity: 0;
    padding-top: 10px;
    text-align: center;
    width: 100%;
}
mau-carousel>figure[data-active] {
    display: block;
    opacity: 1;
}
mau-carousel>figure>figcaption {
    background-color: black;
    bottom: 0;
    color: white;
    font-style: italic;
    padding: 3px;
    position: absolute;
    text-align: center;
    width: 100%;
}
mau-carousel>figure>a{
    display: inline-block;
    height: 100%;
}
mau-carousel>figure>a>img {
    background-color: rgba(255,255,255,0.8);
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: calc(var(--carousel-max-height) * 0.95);
    max-width: calc(var(--carousel-max-height) * 0.95);
    object-fit: cover;
    position: relative;
}
mau-carousel>figure>a>img:hover {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: imgScale;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-name: imgScale;
}
mau-carousel>.carousel-button {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    display: inline;
    font-size: var(--carousel-button-height);
    margin: 0px;
    opacity: 0.8;
    position: absolute;
    top: calc(50% - (var(--carousel-button-height) / 2));
}
mau-carousel>.carousel-button:focus,
mau-carousel>.carousel-button:hover {
    color: rgba(255, 255, 255, 1);
}
mau-carousel>.carousel-button:focus {
    outline: 1px solid black;
}
mau-carousel>.carousel-button i {
    
}
mau-carousel>.carousel-button.next {
    right:0px;
}
mau-carousel>.carousel-button.prev {
    
}

mau-highlight p{
    background-color: #def440;
    color: #222;
}

mau-references {
    
}