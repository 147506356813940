.blog-entry,.blog-entry-preview {
    --code-bg-color: var(--color-text);
    --code-color-text: var(--color-terciary);
    --preview-color-text: var(--color-secondary);
    --body-color:var(--color-text);
    /*--body-font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    --body-font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    --body-font-size: 1.25rem;
    --body-li-color: var(--color-primary);
    --body-li-marker-color: var(--color-secondary);
    --body-blockquote-bg-color: var(--color-secondary);
    --body-blockquote-color: var(--color-text);
    --body-blockquote-line-color: var(--color-primary);
    --body-anchor-color: var(--color-primary);
    --body-anchor-visited-color: var(--color-secondary);
    --body-blockquote-anchor-color: var(--color-terciary);
    --body-blockquote-anchor-visited-color: var(--color-background);

    --bs-card-title-color: var(--color-secondary);
}

/* CUSTOM TAGS */
.up-container

.blog-entry mau-references p {
    margin-bottom: 0.25em;
    margin-top: 0.25em;
    padding-left: 1em;
}
.blog-entry mau-references p a:first-child{
    color: var(--color-primary-soft);
}
.blog-entry mau-references p a:not(:first-child){
    color: var(--color-text);
    font-weight: 500;
    text-decoration: underline;
}

/* CUSTOM TAGS */

.blog-entry>figure {
    padding-top: 10px;
    background-color: transparent;
    border: 1px var(--color-primary-soft) dashed;
    color: white;
    display: block;
    margin-top: 2em;
    max-height: var(--carousel-max-height);
    min-height: var(--carousel-min-height);
    position: relative;
}
.blog-entry>figure>img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: calc(var(--carousel-max-height) * 0.95);
    max-width: calc(var(--carousel-max-height) * 0.95);
    object-fit: cover;
    position: relative;
}
.blog-entry>figure>img:hover {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: imgScale;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-name: imgScale;
}
@keyframes imgScale {
    50% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        z-index: 0;
    }
    100% {
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -o-transform: scale(1.5);
        -ms-transform: scale(1.5);
        transform: scale(1.5);
        z-index: 3;
    }
}
  
.blog-entry>figure>figcaption {
    background-color: black;
    bottom: 0;
    color: white;
    font-style: italic;
    padding: 3px;
    position: absolute;
    text-align: center;
    width: 100%;
}

.card-title {
    font-weight: 600;
}
.content-container {
    /*--body-head-font-family: "Cinzel",serif;*/
    --body-head-font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 1em 1em 1em 1em;
}
.metadata {
    --metadata-author-color: var(--color-primary);
    --metadata-date-color: var(--color-primary);
    --metadata-font-family: "Cinzel",serif;
    --metadata-font-size: 1rem;
}
.tags {
    --tag-bg-color: lightgrey;
    --tag-border-color: var(--color-background);
    display: none;
    margin-top: 2em;
}
.blog-button-container {
    display: table;
    margin: auto;
}
.blog-entry {
    text-decoration: none;
    font-family: var(--body-font-family);
    padding: 0em 0.75em 1.5em 0.75em;
}

.blog-card {
    border: "none";
}
.blog-card-body {
    background-color: white;
}
.blog-card-footer {
    background-color: var(--color-primary);
    text-align: center;
}
.blog-card-header {
    background-color: var(--color-primary);
}

.blog-entry blockquote {
    background-color: var(--body-blockquote-bg-color);
    line-height: var(--body-font-size);
    padding-left: 15px;
    border-left: 10px solid var(--body-blockquote-line-color);
    box-sizing: border-box;
}
.blog-entry blockquote a {
    color: var(--body-blockquote-anchor-color);
    font-weight: 600;
    font-size: var(--body-font-size) * 0.8;
}
.blog-entry blockquote a:visited {
    color: var(--body-blockquote-anchor-visited-color);
}
.blog-entry blockquote p {
    color: var(--body-blockquote-color);
    font-size: var(--body-font-size) * 0.8;
}
.blog-entry .central-img {
    display: block;
    margin: auto;
    margin-bottom: 20px;
    width: 50%;
}
.blog-entry ol {
    margin-top: 1rem;
    margin-bottom: -1rem;
}
.blog-entry li {
    color: var(--body-li-color);
}
.blog-entry li::marker {
    color: var(--body-li-marker-color);
}
.blog-entry p {
    color: var(--body-color);
    letter-spacing: -0.003em;
    line-height: 32px;
    margin-top: 2em;
    font-size: var(--body-font-size);
    margin-bottom: -0.46em;
    font-family: var(--body-font-family);
    font-weight: 400;
}
.blog-entry pre {
    background-color: var(--code-bg-color);
    border-radius: 0.5em;
    color: var(--code-color-text);
    font-weight: 600;
    padding: 10px;
}

.blog-entry-preview {
    border: 2px solid black;
    border-radius: 1em;
    overflow-y: hidden;
    padding: 10px;
    position: relative;
}
.blog-entry-preview-author {
    background-color: var(--color-secondary);
    border-radius: 0.5em;
    color: white;
    float: right;
    padding: 0.3em;
    width: 100%;
    text-align: center;
}
.blog-entry-preview-date {
    background-color: var(--color-secondary);
    border-radius: 0.5em;
    color: white;
    float: left;
    padding: 0.3em;
    width: 100%;
    text-align: center;
}
.blog-entry-reading-time {
    background-color: var(--color-secondary);
    border-radius: 0.5em;
    color: white;
    float: left;
    padding: 0.3em;
    width: 100%;
    text-align: center;
}
.blog-entry-preview-kicker {
    color: var(--bs-secondary-color);
}

.blog-entry-preview a, .blog-entry a {
    color: var(--body-anchor-color);
    font-weight: 700;
    text-decoration: none;
}
.blog-entry-preview a:visited, .blog-entry a:visited {
    color: var(--body-anchor-visited-color);
}
.blog-entry-preview p {
    color: var(--preview-color-text);
}
.blog-entry-preview li p {
    color: var(--body-li-color);
}
.blog-entry-preview-button {
    position: absolute;
    right: 5%;
    bottom: 5%;
}

.content-container .kicker {
    color: var(--color-text);
    font-weight: 400;
    margin-top: 0;
}
.read-button {
    display: block;
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
}

label.btn.label-mau {
    background-color: transparent;
}
.metadata {
    font-family: var(--metadata-font-family);
    font-size: var(--metadata-font-size);
    display: table-cell;
    text-align: center;
}
.metadata-container {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.metadata-author {
    color: var(--metadata-author-color);
}
.metadata-date {
    color: var(--metadata-date-color);
}
.metadata-reading-time,.metadata-reading-time > i {
    color: white;
}
.tag {
    background-color: var(--tag-bg-color);
    border: 1px solid var(--tag-border-color);
    pointer-events: none;
}
.up-container {
    position: sticky;
    bottom: 5%;
    z-index: 99;
    width: 30%;
    max-width: 100px;
    right: 20px;
    left: 90%;
    background-color: var(--color-background);
    border-radius: 50%;
}
.up-container:hover {
    cursor: pointer;
}