.card-project {
    background-color: var(--color-primary-soft);
    border-radius: 2em;
    /*height: 400px;*/
    margin-top: 1.25rem;
    overflow-x: clip;
    /*overflow-y: scroll;*/
    padding: 15px;
    text-align: center;
    width: 100%;

    --color-project-separator: var(--color-primary);
}
.card-project-anchor {
    text-decoration: none;
}
.card-project-body {
    /*border-bottom: 1px solid var(--color-project-separator);*/
    /*border-top: 1px solid var(--color-project-separator);*/
    margin-top: 5px;
    margin-bottom: 5px;
}
.card-project-footer {

}
.card-project-header {
    background-position: 50% 50%;
    border-radius: 1em;
    background-size: 200px;
    height: 9.25rem;
    margin: -1.25rem -1.25rem 0px -1.25rem;
    overflow-y: hidden;
}
.card-project-text {
    color: var(--color-background);
}
.card-project-title {
    color: var(--color-text);
    font-weight: 600;
}